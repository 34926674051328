<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-account-supervisor"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">Usuários</div>
          </template>

          <v-btn
            id="btn-novo-usuario"
            v-if="tipoAutenticacao === 'database' && accessReleased('USUARIOS_ADICIONAR')" 
            color="success"
            dark
            elevation="1"
            class="mt-5"
            style="float: left"
            relative
            text
            medium
            @click="$router.push('edit')"
          >
            <v-icon
              left
              size="30px"
            >
              mdi-plus-circle
            </v-icon>
            Novo Usuário
          </v-btn>

          <v-text-field
            id="usuarios-historico-search"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search bs-usuario"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />

          <v-divider class="mt-10" />

          <v-data-table
            class="usuario-table"
            :headers="[
              {
                text: '#',
                value: 'id'
              },
              {
                text: 'Nome',
                value: 'nome'
              },
              {
                text: 'Login',
                value: 'login'
              },
              {
                text: 'E-mail',
                value: 'email'
              },
              {
                text: 'Perfis',
                value: 'perfis'
              },
              {
                text: 'Status',
                value: 'status'
              },
              {
                sortable: false,
                text: 'Ações',
                value: 'actions',
                class: 'pl-4'
              }
            ]"
            :items="usuarios"
            :search.sync="search"
            :headerProps="{
              sortByText: 'Ordenar Por'
            }"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            :mobile-breakpoint="800"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            no-results-text="Nenhum registro correspondente encontrado"
          >
            <template v-slot:[`item.perfis`]="{ item }">
              {{ item.perfis.join(', ') }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <status :status="item.ativo" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                id="action-edit-usuario"
                v-if="
                  tipoAutenticacao === 'database' &&
                  accessReleased('USUARIOS_EDITAR')
                "
                :class="!canEditItem(item) ? 'cursor-block' : ''"
                :disabled="!canEditItem(item)"
                color="gray"
                min-width="0"
                class="px-1 ml-n1"
                fab
                icon
                x-small
                @click="$router.push(`edit/${item.id}`)"
              >
                <v-icon small> mdi-pencil </v-icon>
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="action-more-usuario"
                    color="white"
                    class="ml-1"
                    height="22px"
                    width="22px"
                    fab
                    x-small
                    elevation="1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="accessReleased('VISUALIZAR_PERFIL_USUARIO')"
                    @click="$router.push(`visualizar-perfil/${item.id}`)"
                  >
                    <v-list-item-title>
                      <v-icon small> mdi-account </v-icon>
                      Visualizar Perfil
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="canChangeStatus(item)"
                    @click="openDialogChangeStatus(item)"
                  >
                    <v-list-item-title>
                      <v-icon
                        small
                        v-if="item.ativo === 'Ativo'"
                      >
                        mdi-account-cancel
                      </v-icon>
                      <v-icon v-else> mdi-account </v-icon>
                      <span v-if="item.ativo === 'Ativo'"> Inativar </span>
                      <span v-else> Ativar </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`footer.page-text`]>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="getUsuarios()"
                    >mdi-refresh</v-icon
                  >
                </template>
                <span>Clique aqui para recarregar os usuários</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <dialog-change-status
            :dialog="dialogChangeStatus"
            @update:dialog="dialogChangeStatus = $event"
            @submitTriggered="changeUserStatus(dialogChangeStatusData.id)"
          >
            <template slot="title">
              <span v-if="dialogChangeStatusData.ativo === 'Ativo'">
                Inativar
              </span>
              <span v-else> Ativar </span> usuário #{{
                dialogChangeStatusData.id
              }}
            </template>
            <template slot="body">
              Tem certeza que deseja
              <span v-if="dialogChangeStatusData.ativo === 'Ativo'">
                inativar
              </span>
              <span v-else> ativar </span>
              o usuário <strong>{{ dialogChangeStatusData.nome }}</strong
              >?
            </template>
          </dialog-change-status>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService';
import UsuariosService from '@/services/UsuariosService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  name: 'UsuariosListar',

  mixins: [refreshDataMixins, profilePermissionsMixin],

  components: {
    Status: () => import('@/components/general/Status.vue'),
    DialogChangeStatus: () => import('@/components/general/DialogChangeStatus')
  },

  data() {
    return {
      usuarios: [],
      usuarioAdmin: false,
      tipoAutenticacao: null,
      search: '',
      dialogChangeStatus: false,
      dialogChangeStatusData: {
        id: undefined,
        nome: undefined
      },
      loading: false
    };
  },

  mounted() {
    this.refreshData(this.getUsuarios);
    this.getUsuarioAdmin();
    this.getTipoAutenticacao();
  },

  computed: {
    usuario() {
      return this.$store.getters.getUser;
    }
  },

  methods: {
    getUsuarios() {
      this.usuarios = [];
      this.loading = true;

      UsuariosService.getUsuarios()
        .then(({ data }) => {
          this.usuarios = data.data;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error('Erro ao recuperar os usuários.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },

    async getUsuarioAdmin() {
      this.usuarioAdmin = await AuthService.userIsAdmin();
    },

    async getTipoAutenticacao() {
      this.tipoAutenticacao = await AuthService.getTipoAutenticacao();
    },

    canEditItem() {
      return this.tipoAutenticacao === 'database' && this.usuarioAdmin;
    },

    canChangeStatus(item) {
      return (
        this.tipoAutenticacao === 'database' &&
        this.usuarioAdmin &&
        this.usuario.id !== item.id
      );
    },

    changeUserStatus(id) {
      UsuariosService.alteraStatus(id)
        .then(() => {
          this.$toast.success('Status do usuário atualizado com sucesso.', '', {
            position: 'topRight'
          });
          this.getUsuarios();
        })
        .catch((error) => {
          this.$toast.error('Erro ao atualizar o status do usuário.', '', {
            position: 'topRight'
          });
          console.error(error);
        });

      this.dialogChangeStatus = false;
    },

    openDialogChangeStatus(usuario) {
      this.dialogChangeStatusData = usuario;
      this.dialogChangeStatus = true;
    }
  }
};
</script>

<style>
@media screen and (min-width: 800px) {
  .usuario-table table tr td:nth-child(2) {
    max-width: 200px;
  }

  .usuario-table table tr td:nth-child(5),
  .usuario-table table tr td:nth-child(6) {
    white-space: nowrap !important;
  }
}

@media screen and (max-width: 480px) {
  .bs-usuario {
    width: 215.36px !important;
  }
}
</style>
