var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-account-supervisor","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Usuários")])]},proxy:true}])},[(_vm.tipoAutenticacao === 'database' && _vm.accessReleased('USUARIOS_ADICIONAR'))?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"id":"btn-novo-usuario","color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('edit')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Novo Usuário ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-usuario",staticStyle:{"max-width":"250px"},attrs:{"id":"usuarios-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"usuario-table",attrs:{"headers":[
            {
              text: '#',
              value: 'id'
            },
            {
              text: 'Nome',
              value: 'nome'
            },
            {
              text: 'Login',
              value: 'login'
            },
            {
              text: 'E-mail',
              value: 'email'
            },
            {
              text: 'Perfis',
              value: 'perfis'
            },
            {
              text: 'Status',
              value: 'status'
            },
            {
              sortable: false,
              text: 'Ações',
              value: 'actions',
              class: 'pl-4'
            }
          ],"items":_vm.usuarios,"search":_vm.search,"headerProps":{
            sortByText: 'Ordenar Por'
          },"footer-props":{
            itemsPerPageText: 'Itens por Página'
          },"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.perfis",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.perfis.join(', '))+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('status',{attrs:{"status":item.ativo}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(
                _vm.tipoAutenticacao === 'database' &&
                _vm.accessReleased('USUARIOS_EDITAR')
              )?_c('v-btn',{staticClass:"px-1 ml-n1",class:!_vm.canEditItem(item) ? 'cursor-block' : '',attrs:{"id":"action-edit-usuario","disabled":!_vm.canEditItem(item),"color":"gray","min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$router.push(("edit/" + (item.id)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more-usuario","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(_vm.accessReleased('VISUALIZAR_PERFIL_USUARIO'))?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push(("visualizar-perfil/" + (item.id)))}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-account ")]),_vm._v(" Visualizar Perfil ")],1)],1):_vm._e(),(_vm.canChangeStatus(item))?_c('v-list-item',{on:{"click":function($event){return _vm.openDialogChangeStatus(item)}}},[_c('v-list-item-title',[(item.ativo === 'Ativo')?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-account-cancel ")]):_c('v-icon',[_vm._v(" mdi-account ")]),(item.ativo === 'Ativo')?_c('span',[_vm._v(" Inativar ")]):_c('span',[_vm._v(" Ativar ")])],1)],1):_vm._e()],1)],1)]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.getUsuarios()}}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar os usuários")])])]},proxy:true}],null,true)}),_c('dialog-change-status',{attrs:{"dialog":_vm.dialogChangeStatus},on:{"update:dialog":function($event){_vm.dialogChangeStatus = $event},"submitTriggered":function($event){return _vm.changeUserStatus(_vm.dialogChangeStatusData.id)}}},[_c('template',{slot:"title"},[(_vm.dialogChangeStatusData.ativo === 'Ativo')?_c('span',[_vm._v(" Inativar ")]):_c('span',[_vm._v(" Ativar ")]),_vm._v(" usuário #"+_vm._s(_vm.dialogChangeStatusData.id)+" ")]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja "),(_vm.dialogChangeStatusData.ativo === 'Ativo')?_c('span',[_vm._v(" inativar ")]):_c('span',[_vm._v(" ativar ")]),_vm._v(" o usuário "),_c('strong',[_vm._v(_vm._s(_vm.dialogChangeStatusData.nome))]),_vm._v("? ")])],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }